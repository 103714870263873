import React from "react";
import styles from "./PageTitle.module.css";
import { BsPencilSquare } from "react-icons/bs";
import RingLoader from "react-spinners/RingLoader";

export default function PageTitle({ title, onClick, hide, loading, edit }) {
  return (
    <div className={styles.pageTitle}>
      <h1 className={styles.title}>{title}</h1>

      {!hide &&
        (loading ? (
          <RingLoader color={"#ffffff"} loading={loading} size="30px" />
        ) : edit ? (
          <span className={styles.stopSpan} onClick={onClick}>
            Stop Editing
          </span>
        ) : (
          <BsPencilSquare className={styles.button} onClick={onClick} />
        ))}
    </div>
  );
}
