import React from "react";
import { AppStreamButton } from "../AppComponents";
import RingLoader from "react-spinners/RingLoader";
import styles from "./AppDrawer.module.css";
import Header from "./Header";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import Grid from "./Grid";

export default function AppDrawer({
  setAuthenticated,
  applications,
  loading,
  toggleMyApps,
}) {
  return (
    <div className={`${styles.appHolder} ${loading && styles.loader}`}>
      {!loading && (
        <Header title="Applications">
          <BsFillGrid3X3GapFill
            className={styles.gridApps}
            onClick={() => toggleMyApps()}
          />
        </Header>
      )}

      {loading ? (
        <RingLoader
          size={80}
          color={"#ffffff"}
          loading={loading}
          className={styles.loader}
        />
      ) : (
        <Grid>
          {applications.map((app) => (
            <AppStreamButton
              mode={app.mode}
              key={`${app.appName} ${app.title} ${app.mode} Main Column`}
              application={app}
              spinClass="spin"
              setAuthenticated={setAuthenticated}
            />
          ))}
        </Grid>
      )}
    </div>
  );
}
