import React, { useState } from "react";
import styles from "./Header.module.css";

function Header({ title, children }) {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{title}</div>
      <div className={styles.buttonHolder}>{children}</div>
    </div>
  );
}

export default Header;
