import React, { useState, useContext, useEffect } from "react";
import {
  ContactInformation,
  CompanyInformation,
  SecurityQuestions,
  SecurityInformation,
  SponsorInformation,
  FormContainer,
  formDataInit,
  FormSelection,
  CACSelection,
  SAICInformation,
  AccountType,
  steps as nonEmployeeSteps,
  employeeSteps,
  corpNonEmployeeSteps
} from "./RegistrationForms";
import { ToastContext } from "../../layout";
import { getCACSubject, submitRegistration } from "../../services/ReadyOneAPI";

function VIPRAccountRequestForm() {
  const [currentStep, setCurrentStep] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState(formDataInit);
  const [disableNext, setDisableNext] = useState(false);
  const [steps, setSteps] = useState(nonEmployeeSteps);
  const [stepComponents, setStepComponents] = useState({
    0: CACSelection,
    1: FormSelection,
    2: AccountType,
    3: ContactInformation,
    4: CompanyInformation,
    5: SecurityQuestions,
    6: SponsorInformation,
  })

  const { notifyError, notifySuccess } = useContext(ToastContext);
  const StepComponent = stepComponents[currentStep];

  useEffect(() => {
    if (currentStep === 1) {
      if (formData.SAICEmployee === "Yes") {
        setDisableNext(false)
        setStepComponents({
          0: CACSelection,
          1: FormSelection,
          2: SAICInformation
        })
        setSteps(employeeSteps)
      } else {
        setDisableNext(false)
        setStepComponents({
          0: CACSelection,
          1: FormSelection,
          2: AccountType,
          3: ContactInformation,
          4: CompanyInformation,
          5: SecurityQuestions,
          6: SponsorInformation,
        })
        setSteps(nonEmployeeSteps)
      }
      setCurrentStep((prevStep) => prevStep + 1);
    } else if (currentStep === 6) {
      if (formData.saicSponsorEmail.includes("saic.com") && formData.saicSponsorEmail !== formData.email) {
        setDisableNext(false)
      } else {
        if (formData.saicSponsorEmail === formData.email) notifyError("Sponsor email must be different than requester email")
        setDisableNext(true)
      }
    } else if (currentStep === 2) {
      if (formData.SAICEmployee !== "Yes") {
        console.log(formData.accountType)
        if (formData.accountType === "corporate") {
          setStepComponents({
            0: CACSelection,
            1: FormSelection,
            2: AccountType,
            3: ContactInformation,
            4: CompanyInformation,
            5: SecurityInformation,
            6: SponsorInformation,
          })
          setSteps(corpNonEmployeeSteps)
        } else {
          setStepComponents({
            0: CACSelection,
            1: FormSelection,
            2: AccountType,
            3: ContactInformation,
            4: CompanyInformation,
            5: SecurityQuestions,
            6: SponsorInformation,
          })
          setSteps(nonEmployeeSteps)
        }
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        if (formData.saicSponsorEmail.includes("saic.com") && formData.saicSponsorEmail !== formData.email) {
          setDisableNext(false)
        } else {
          if (formData.saicSponsorEmail === formData.email) notifyError("Sponsor email must be different than requester email")
          setDisableNext(true)
        }
      }
    }
  }, [formData])


  useEffect(() => {
    if (currentStep === 1 || currentStep === 0) {
      setDisableNext(true)
    } else if (currentStep === 6) {
      setDisableNext(true)
    }
    else {
      setDisableNext(false)
    }
  }, [currentStep])

  function closeRegForm() {
    //window.location.href = "https://www.vipr-govcloud.com/";
    console.log(JSON.stringify(formData));
  }

  const nextStep = () => {
    const missingFields = steps[currentStep].requiredFields.filter((field) => {
      return !formData[field];
    });

    if (missingFields.length > 0) {
      notifyError(
        `Please fill out the following required fields: ${missingFields
          .map((field) =>
            field
              .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
              .replace(/^./, (str) => str.toUpperCase())
          )
          .join(", ")}`,
        20000
      );
      return;
    } else {
      if (currentStep === steps.length - 1) {
        handleSubmit()
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }
  };

  const prevStep = () => {
    if (currentStep === 7) {
      setCurrentStep(1);
    } else {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "cacCard") {
      setDisableNext(true)

      getCACSubject().then((result) => {
        try {
          setFormData({
            ...formData,
            ["cacSubject"]: result.subject,
            ["cacCard"]: true,
          });
          setDisableNext(false);
          notifySuccess("CAC Card Successfully Registered", 1000);
        } catch (err) {
          notifyError("CAC Card could not be read at this time. Please try again later", 1000);
          setDisableNext(false);
        }
      });

    }
    if (e.target.name === "email") {
      setFormData({
        ...formData,
        ["id"]: e.target.value,
        [e.target.name]: e.target.value,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });

    }
  };

  const handleSubmit = () => {
    try {
      submitRegistration(formData).then((result) => {
        console.log(result);
        if (result.id) {
          setSubmitted(true);
          notifySuccess("Account Request Submitted", 1000);
        }
      });
    } catch (error) {
      notifyError(`Error submitting form: ${error}`, 1000);
    }
  };

  return (
    <FormContainer
      submitted={submitted}
      closeRegForm={closeRegForm}
      currentStep={currentStep}
      steps={steps}
      prevStep={prevStep}
      nextStep={nextStep}
      handleSubmit={handleSubmit}
      disableNext={disableNext}
    >
      <StepComponent formData={formData} handleChange={handleChange} nextStep={nextStep} />
    </FormContainer>
  );
}

export default VIPRAccountRequestForm;
