import React, { useState, useEffect, useContext } from "react";
import { BsQuestionCircle } from "react-icons/bs";
import UserFeedbackModal from "../../components/Modals/UserFeedback/FeedbackModal";
import styles from "./Footer.module.css";

export default function Footer() {
  const [show, setShow] = useState(false);

  return (
    <div className={styles.footer}>
      <div className={styles.feedback}>
        <BsQuestionCircle onClick={() => setShow(!show)} />
        <span className={styles.text}>Need help or have feedback?</span>
        <UserFeedbackModal show={show} secondButton={setShow} mode="confirm" />
      </div>
    </div>
  );
}
