import React from "react";
import { useOuterClick } from "../../../../../../../hooks";
import styles from "./AppStreamMenu.module.css";

export default function AppStreamMenu({
  appStreams,
  unblur,
  checked,
  launchAppStream,
}) {
  const innerRef = useOuterClick((ev) => {
    unblur();
  });

  return (
    <nav className={styles.menu}>
      <input
        checked={checked}
        className={styles.menuToggler}
        type="checkbox"
        onClick={() => unblur()}
        ref={innerRef}
      />
      <label for={styles.menuToggler}></label>

      <ul>
        {appStreams.map((app) => (
          <li
            className={styles.menuItem}
            key={app.title}
            onClick={() => checked && launchAppStream(app)}
          >
            <a className={styles.title}>{app.title}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
