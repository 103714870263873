import React, { useState, useContext, useEffect, Component } from "react";
import styles from "./Login.module.css";
import { Button, Form, Col, DropdownButton, Dropdown } from "react-bootstrap";
import { AccountContext } from "./Account";
import { useLocation, Navigate, Link } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import ImageHelper from "../components/ImageHelper";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [redirect, setRedirect] = useState("ssmw");
  const options = [
    {
      title: "Space Sensing - Missile Warning",
      env: "ssmw",
    },
    {
      title: "Nebula",
      env: "neb",
    },
    {
      title: "SpaceDEN",
      env: "sden",
    },
    {
      title: "PNT",
      env: "pnt",
    },
  ];

  const { authenticate, getSession, setEnvironment } =
    useContext(AccountContext);
  const search = useLocation().hash;

  const accessToken = new URLSearchParams(search).get("#access_token") || "";
  useEffect(() => {
    setLoading(true);
    console.log('Referrer:', document.referrer);
    getSession(accessToken)
      .then((result) => {
        console.log("FRom Loging user effect:", result.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const onSubmit = (event) => {
    authenticate(email, password)
      .then((data) => {
        setFailed(false);
        setIsAuth(true);
      })
      .catch((err) => {
        //console.error("Failed to login!: ", err);
        setFailed(true);
      });
  };

  const openCACLogin = () => {
    console.log(window.location.hostname);
    switch (window.location.hostname) {
      case "dev.vipr-govcloud.com":
        window.location.href = `https://viprgovcloud.auth-fips.us-gov-west-1.amazoncognito.com/authorize?identity_provider=keycloak&response_type=token&client_id=1heovbd274phpd22bblkn0l1jm&redirect_uri=https://dev.vipr-govcloud.com/`;
        break;
      case "vipr-govcloud.com":
        window.location.href = `https://viprgovcloud.auth-fips.us-gov-west-1.amazoncognito.com/authorize?identity_provider=keycloak&response_type=token&client_id=1heovbd274phpd22bblkn0l1jm&redirect_uri=https://vipr-govcloud.com/`;
        break;
      case "www.vipr-govcloud.com":
        window.location.href = `https://viprgovcloud.auth-fips.us-gov-west-1.amazoncognito.com/authorize?identity_provider=keycloak&response_type=token&client_id=1heovbd274phpd22bblkn0l1jm&redirect_uri=https://www.vipr-govcloud.com/`;
        break;
      case "vipr.readyone.net":
        window.location.href = `https://viprgovcloud.auth-fips.us-gov-west-1.amazoncognito.com/authorize?identity_provider=keycloak&response_type=token&client_id=1heovbd274phpd22bblkn0l1jm&redirect_uri=https://vipr.readyone.net/`;
        break;
      case "localhost":
        window.location.href = `https://viprgovcloud.auth-fips.us-gov-west-1.amazoncognito.com/authorize?identity_provider=keycloak&response_type=token&client_id=1heovbd274phpd22bblkn0l1jm&redirect_uri=http://localhost:3000/`;
        break;
      default:
        window.location.href = `https://viprgovcloud.auth-fips.us-gov-west-1.amazoncognito.com/authorize?identity_provider=keycloak&response_type=token&client_id=1heovbd274phpd22bblkn0l1jm&redirect_uri=https://dev.vipr-govcloud.com/`;
        break;
    }
  };

  const openSAICLogin = () => {
    console.log(window.location.hostname);
    switch (window.location.hostname) {
      case "dev.vipr-govcloud.com":
        window.location.href =
          "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://dev.vipr-govcloud.com/";
        break;
      case "vipr-govcloud.com":
        window.location.href =
          "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://www.vipr-govcloud.com/";
        break;
      case "www.vipr-govcloud.com":
        window.location.href =
          "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://www.vipr-govcloud.com/";
        break;
      case "vipr.readyone.net":
        window.location.href =
          "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://vipr.readyone.net/";
        break;
      case "localhost":
        window.location.href =
          "https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=http://localhost:3000/";
        break;
      default:
        window.location.href = `https://eifauth.auth-fips.us-gov-west-1.amazoncognito.com/authorize?idp_identifier=saic&response_type=token&client_id=4am2prse8uqojha4hmpu4v7qe9&redirect_uri=https://dev.vipr-govcloud.com/`;
        break;
    }
  };

  useEffect(() => {
    console.log(redirect);
  }, [redirect]);

  return loading ? (
    <div className={styles.loginRingLoader}>
      <RingLoader size={80} color={"#ffffff"} loading={loading} />
    </div>
  ) : isAuth ? (
    <Navigate to="/Getting-Started" />
  ) : (
    <div className={styles.loginWholePage}>
      {/* style={{ backdropFilter: blur + 4 }} */}
      <div className={styles.loginModal} show={true} centered>
        <div className={styles.loginModalLogo} />

        <div className={styles.loginModalRo}>
          <div className={styles.brandLogo}>
            <ImageHelper mode="bgviprlogo" width="100%" onClick={() => { }} />
          </div>
        </div>

        <div className={styles.loginModalHeader}>
          <div>
            <div className={styles.loginMessage}>Please login to continue.</div>
          </div>
        </div>
        <div className={styles.loginButtonRow}>
          <div className={styles.loginModalFooter}>
            <Button
              variant="danger"
              className={styles.loginModalButtonOne}
              onClick={() => {
                openSAICLogin();
              }}
            >
              SAIC SSO Login
            </Button>
            <Button
              variant="danger"
              className={styles.loginModalButtonOne}
              onClick={() => {
                openCACLogin();
              }}
            >
              CAC Login
            </Button>
          </div>
          <div className={styles.loginModalFooter}>
            <Link to="/Registration" className={styles.loginModalButtonTwo}>
              <Button variant="danger" className={styles.loginModalButtonThree}>
                Request Access
              </Button>
            </Link>
          </div>
        </div>
        <div className={styles.loginModalFooterDisc}>
          <Button className={styles.discButton} onClick={() => setShow(!show)}>
            <div className={styles.loginDiscText} id="disclaimer">
              By accessing this site, you are agreeing to SAIC's Acceptable Use
              Policy.
              <br />
              Click to learn more.
              {show ? (
                <div className={styles.policyButton}>
                  This computer system is the property of SAIC and includes all
                  related equipment, networks, and network devices. This
                  computer system may contain US government information and is
                  only for use by authorized users for business purposes. There
                  is no right of privacy in this computer system. Use
                  constitutes consent and acknowledgement that the computer
                  system, and any information placed or sent over the computer
                  system, is monitored, recorded and subject to audit and
                  collection for all lawful purposes including in relation to
                  investigations and/or legal proceedings. Any unauthorized use,
                  accessing of or attempt to access this computer system, or any
                  unauthorized alteration, damage, removing, addition, or
                  destruction of any data, program or software in this computer
                  system is subject to criminal and civil prosecution under
                  state and/or federal law. Unauthorized use may result in the
                  delivery of possible evidence collected during monitoring to
                  law enforcement officials. Failure to comply with this notice
                  may be grounds for disciplinary action, up to and including
                  termination and/or civil and criminal prosecution.
                  <br />
                </div>
              ) : null}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
