import React, { useEffect, useContext } from "react";
import { AccountContext } from "../";
import styles from "./EnvironmentSelect.module.css";
import { useNavigate, useLocation } from "react-router-dom";

export default function EnvironmentSelect() {
  const { setEnvironment, facades } = useContext(AccountContext);
  const navigate = useNavigate();
  const search = useLocation().hash;
  const accessToken = new URLSearchParams(search).get("#access_token") || "";

  useEffect(() => {
    if (accessToken) navigate("/");
  },[])
  
  return facades.length > 1  && (
    <div className={styles.container}>
      <div className={styles.modal}>
        <span className={styles.title}>Select Environment</span>
        <div className={styles.enviroments}>
          {facades.map((opt) => (
            <button onClick={() => setEnvironment(opt)}>{opt.title}</button>
          ))}
        </div>
      </div>
    </div>
  );
}
