import React from "react";
import styles from "./AppStreamDeleteButton.module.css";

export default function AppStreamDeleteButton({
  hidden,
  add,
  isMyApp,
  onClick,
}) {
  return (
    <button
      className={`${
        isMyApp
          ? styles.checkButton
          : add
          ? styles.addButton
          : styles.closeButton
      } ${hidden && styles.hidden}`}
      onClick={onClick}
    >
      <span className={styles.x} onClick={onClick}>
        {isMyApp ? "✓" : add ? "+" : "x"}
      </span>
    </button>
  );
}
