import React, { useEffect, useContext } from "react";
import { Layout, Background, ToastProvider } from "./layout";
import { AccountContext, Login, EnvironmentSelect } from "./auth";
import { Applications, HowTo, RequestForm, Registration } from "./pages";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const WorkspaceRedirect = () => {
  useEffect(() => {
    // Redirect to the external URL
    window.location.href = "https://keycloak.vipr-govcloud.com/realms/master/protocol/saml/clients/Workspaces";
  }, []);

  return null; // Render nothing
};

const AppStreamRedirect = () => {
  useEffect(() => {
    // Redirect to the external URL
    window.location.href = "https://keycloak.vipr-govcloud.com/realms/master/protocol/saml/clients/appstream";
  }, []);

  return null; // Render nothing
};

function App() {
  const { authenticated, environment } = useContext(AccountContext);

  return (
    <div className="App">
      <Router>
        {authenticated && environment ? (
          <ToastProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<Applications />} />
                <Route exact path="/Applications" element={<Applications />} />
                {/* Remove the comment if you want to re-add these pages */}
                {/* <Route exact path="/How-To-Resources" element={<HowTo />} />
              <Route exact path="/Request-Form" element={<RequestForm />} /> */}
              </Routes>
            </Layout>
          </ToastProvider>
        ) : authenticated && !environment ? (
          <>
            <Routes>
              <Route path="/" element={<EnvironmentSelect />} />
            </Routes>
            <Background />
          </>
        ) : (
          <>
            <div>
              <ToastProvider>
                <Routes>
                  <Route path="/Registration" exact element={<Registration />} />
                  {/* Redirect /workspace to the specified URL */}
                  <Route path="/workspace" element={<WorkspaceRedirect/>} />
                  <Route path="/workspaces" element={<WorkspaceRedirect/>} />
                  <Route path="/Workspace" element={<WorkspaceRedirect/>} />
                  <Route path="/Workspaces" element={<WorkspaceRedirect/>} />
                  <Route path="/appstream" element={<AppStreamRedirect/>} />
                  <Route path="/Appstream" element={<AppStreamRedirect/>} />
                  <Route path="*" element={<Login />} />
                </Routes>
              </ToastProvider>
              <Background />
            </div>
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
