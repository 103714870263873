import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "../../auth";
import styles from "./Applications.module.css";
import { getUserApps, getTable } from "../../services/ReadyOneAPI";
import { Title, Footer, Video } from "./Layout";
import { AppDrawer, AllApps, MyApps } from "./Apps";
import SSCimg from "../../images/SSCDelta.png";
import { useNavigate, useLocation } from "react-router-dom";

    

const Applications = () => {
  const [myApps, setMyApps] = useState([]);
  const [applications, setApplications] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { username, environment } = useContext(AccountContext);

  const navigate = useNavigate();
  const search = useLocation().hash;
  const accessToken = new URLSearchParams(search).get("#access_token") || "";

  useEffect(() => {
    if (accessToken) navigate("/Applications");
    setLoading(true);
    let appDB = "VIPR_AppStreams"
    //Take this out when you fix everything
    console.log(environment.id)
    if(environment.id === "dtamm" || environment.id === "horizon") appDB="DTAMM_AppStreams"
    getTable(appDB).then((result) => {
      setApplications(
        result.body.Items.sort((a, b) => a.title.localeCompare(b.title)).filter(
          hidden
        ).filter(facadeApps)
      );
      setLoading(false);
    });
    getUserApps(`saic_${username}`).then((result) => {
      if (result?.app_list) {
        setMyApps(result.app_list);
      }
    });
  }, []);

  const hidden = (app) => {
    return !app.hide;
  };

  const facadeApps = (app) => {
    if(environment.id === "dtamm" || environment.id === "horizon") {
      return true;
    }else {
      //Only necessary line in this function
      return environment.apps.some(facadeApp => facadeApp.appName === app.appName);
    }
  }
  

  return (
    <>
      <div
        className={`${styles.gettingStartedWholeBox} ${show && styles.hideBox}`}
      >
        <Title
          title={`Welcome to ${environment.title} Digital Engineering!`}
          leftImage={environment.leftImg}
          rightImage={environment.rightImg}
        />

        <AppDrawer
          toggleMyApps={() => setShow(!show)}
          applications={applications}
          loading={loading}
        />
      </div>
      <MyApps
        applications={applications}
        myApps={myApps}
        setMyApps={setMyApps}
        show={show}
        stopShow={() => setShow(!show)}
      />
    </>
  );
};

export default Applications;
