import React, { createContext, useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import styles from "./Toast.module.css";
import "react-toastify/dist/ReactToastify.css";
import { AccountContext } from "../../auth";
import { getNotifications } from "../../services/ReadyOneAPI";
import { useNavigate } from "react-router-dom";

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toastData, setToastData] = useState(null);
  const [toastQueue, setToastQueue] = useState([]);
  const navigate = useNavigate();
  const { authenticated, logout, setAuthenticated } = useContext(AccountContext);

  useEffect(() => {
    if(authenticated) {
      notifySuccess("Welcome to VIPR Govcloud!", 1500);
    }
    
  }, [authenticated]);

  useEffect(() => {
    getNotifications().then((result) => {
      console.log(result);
      result.map((notif) => {
        if (notif.type === "info") notifyInfo(notif.message, notif.autoClose);
        if (notif.type === "success")
          notifySuccess(notif.message, notif.autoClose);
        if (notif.type === "error") notifyError(notif.message, notif.autoClose);
        if (notif.type === "warning")
          notifyWarning(notif.message, notif.autoClose);
      });
    });
  }, []);

  const notifySuccess = (message, autoClose) => {
    toast.success(message, {
      autoClose: autoClose,
    });
  };

  const notifyError = (message, autoClose) => {
    toast.error(message, {
      autoClose: autoClose,
    });
  };

  const notifyInfo = (message, autoClose) => {
    toast.info(message, {
      autoClose: autoClose,
    });
  };

  const notifyWarning = (message, autoClose) => {
    toast.warning(message, {
      autoClose: autoClose,
    });
  };

  const notifyLogout = (message, autoClose) => {
    toast.error(message, {
      autoClose: autoClose,
      onClose: () => {
        logout(setAuthenticated)
        navigate("/");
      }
    });
  };

  const dismiss = () => toast.dismiss();

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <ToastContext.Provider
      value={{
        notifySuccess,
        notifyError,
        notifyInfo,
        notifyWarning,
        notifyLogout,
        dismiss,
        delay,
      }}
    >
      {children}
      <ToastContainer className={styles.toast} theme="dark" newestOnTop />
    </ToastContext.Provider>
  );
};
