import React from "react";
import styles from "./Title.module.css";

export default function Title({ title, subtitle, leftImage, rightImage }) {
  return (
    <div className={styles.container}>
      {leftImage && (
        <img src={leftImage} className={styles.image} alt="title box" />
      )}
      <h1 className={styles.title}>{title}</h1>
      <h2 className={styles.subtitle}>{subtitle}</h2>
      {rightImage && (
        <img src={rightImage} className={styles.image} alt="title box" />
      )}
    </div>
  );
}
