import React, { useState, useContext } from "react";
import { AccountContext } from "../../../../../auth";
import {ToastContext} from "../../../../../layout";
import { getAppStreamURL } from "../../../../../services/getAppStreamURL";
import styles from "./AppStreamButton.module.css";
import { AppStreamMenu, LinkMenu } from "./Menus";
import { useNavigate } from "react-router-dom";

const AppStreamButton = ({ application, width, disabled, onClick }) => {
  const [blur, setBlur] = useState("");
  const [checked, setChecked] = useState(false);
  const { accessToken, logout, setAuthenticated, environment, provider } = useContext(AccountContext);
  const {notifyInfo, notifySuccess, notifyError, notifyLogout, dismiss, delay} = useContext(ToastContext);
  let navigate = useNavigate();

  const launchAppStream = async (application) => {
    let { appLink, appStreams, appLinks } = application;
    if (appLink || appStreams) {
      !appLinks && !appStreams ? window.open(appLink) : addBlur();
    } else {
      try {
        notifyInfo(`Retrieving ${application.title} Session`)
        getAppStreamURL(await accessToken(), application.appName, environment.id, provider)
          .then((response) => {
            if(response.Message) {
              dismiss()
              notifySuccess(`Launching ${application.title}...`)
              delay(1500).then(() =>  window.open(response.Message))
             
            }else {
              dismiss()
              notifyLogout("Error Retrieving AppStream Session. Click Here to Reauthenticate.", false);
              //delay(3000).then(() =>  reauthenticate())
            }
          })
          .catch((err) => {
            dismiss()
            notifyLogout("Error Retrieving AppStream Session. Click Here to Reauthenticate.", false);
            //delay(3000).then(() =>  reauthenticate())
            console.log(err);
          });
      } catch (err) {
        dismiss()
        notifyLogout("Error Retrieving AppStream Session. Click Here to Reauthenticate.", false);
       // delay(3000).then(() =>  reauthenticate())
      }
    }
  };

  const reauthenticate = () => {
    navigate("/");
    logout(setAuthenticated);
  };

  const addBlur = () => {
    if (!checked) {
      setBlur(styles.blur);
      setChecked(true);
    }
  };

  const removeBlur = () => {
    if (checked) {
      setChecked(false);
      setBlur("");
    }
  };

  return (
    <>
      {!disabled ? (
        <button
          className={styles.button}
          onClick={() => launchAppStream(application)}
        >
          <div className={styles.selector}>
            <img
              style={{
                width: width,
              }}
              src={application.image}
              className={`request-shadow ${blur}`}
            />
            {application.appLinks && (
              <LinkMenu
                links={application.appLinks}
                unblur={removeBlur}
                checked={checked}
              />
            )}
            {application.appStreams && (
              <AppStreamMenu
                appStreams={application.appStreams}
                unblur={removeBlur}
                checked={checked}
                launchAppStream={launchAppStream}
              />
            )}
          </div>

          <span>{application.title}</span>
        </button>
      ) : (
        <button className={styles.button} onClick={onClick}>
          <div className={styles.selector}>
            <img
              style={{
                width: width,
              }}
              src={application.image}
              className={`request-shadow ${blur}`}
            />
          </div>
          <span>{application.title}</span>
        </button>
      )}
    </>
  );
};

export default AppStreamButton;
