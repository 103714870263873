import React, { createContext, useState, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { CognitoAuth } from "amazon-cognito-auth-js";
// import * as AWS from "aws-sdk";
import Pool from "./UserPool";
import { checkIP, getUserFacades } from "../services/ReadyOneAPI";

var authData = {
  ClientId: "1heovbd274phpd22bblkn0l1jm", // Your client id here
  AppWebDomain: "vipr-govcloud.com",
  TokenScopesArray: ["email", "openid", "aws.cognito.signin.user.admin"], // e.g.['phone', 'email', 'profile','openid', 'aws.cognito.signin.user.admin'],
  RedirectUriSignIn: "/",
  RedirectUriSignOut: "/",
  IdentityProvider: "saic", // e.g. 'Facebook',
  UserPoolId: "us-gov-west-1_xH3EHsOkp", // Your user pool id here
  AdvancedSecurityDataCollectionFlag: false, // e.g. true
};
var auth = new CognitoAuth(authData);

const AccountContext = createContext();

const Account = (props) => {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(null);
  const [environment, setEnvironment] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [internal, setInternal] = useState(false);
  const [facades, setFacades] = useState([]);
  const [provider, setProvider] = useState("")

  useEffect(()=> {console.log(provider)}, [provider])

  const updateFacades = () => {
    if (email) {
      getUserFacades(email.toLowerCase()).then(result => {
        console.log(result)
        setFacades(result)
        
        if (result.length < 1) {
          setEnvironment("default")
        }else if (result.length < 2) {
          setEnvironment(result[0])
        }
      })
    }
  }

  useEffect(() => {
    checkIP().then((result) => {
      console.log(result);
      setInternal(result.internal);
    })
  }, [])

  useEffect(() => {
    updateFacades();
    
  }, [email])

  useEffect(() => {
    if (email) {
      let user = email.split("@")[0];
      let count = user.split(".").length;
      if (count < 3) {
        setLastName(
          email.split(".")[1].charAt(0).toUpperCase() +
          email.split(".")[1].split("@")[0].slice(1)
        );
      } else {
        setLastName(
          email.split(".")[2].charAt(0).toUpperCase() +
          email.split(".")[2].split("@")[0].slice(1)
        );
      }
      setFirstName(
        email.split(".")[0].charAt(0).toUpperCase() +
        email.split(".")[0].slice(1)
      );
    }
  }, [email]);

  

  const getSession = async (accessToken) => {
    if (accessToken) {
      var curUrl = window.location.href;

      auth.userhandler = {
        onSuccess: function (result) {
          //console.log("Sign in success");
          //console.log(result);
        },
        onFailure: function (err) {
          console.error("Error!");
        },
      };

      auth.parseCognitoWebResponse(curUrl);
      auth.getSession();
    }

    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (user) {
        user.getSession((err, session) => {
          if (err) {
            user.signOut();

            reject();
          }

          user.getUserAttributes((err, data) => {
            if (err) {
              user.signOut();

              reject();
            }
            console.log("Get User Attributes: ", data);
            data.map((attr) => {
              if (attr.Name === "email") setEmail(attr.Value);
              if (attr.Name === "identities") {
                if (JSON.parse(attr.Value)[0]) {
                  if (JSON.parse(attr.Value)[0]["userId"]) {
                    setUsername(JSON.parse(attr.Value)[0]["userId"]);
                  }
                  if (JSON.parse(attr.Value)[0]["providerName"]) {
                    setProvider(JSON.parse(attr.Value)[0]["providerName"]);
                  }
                }
              }
            });
            setAuthenticated(true);
            resolve({ session: session, data: data });
          });
        });
      } else {
        reject();
      }
    });
  };

  const getUserName = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (user) {
        user.getUserAttributes((err, data) => {
          if (err) {
            //console.log(err);
            reject(err);
          }

          data.map((attr) => {
            if (attr.Name === "email") setEmail(attr.Value);
            if (attr.Name === "identities") {
              if (JSON.parse(attr.Value)[0]) {
                if (JSON.parse(attr.Value)[0]["userId"]) {
                  resolve(JSON.parse(attr.Value)[0]["userId"]);
                }
                if (JSON.parse(attr.Value)[0]["providerName"]) {
                  setProvider(JSON.parse(attr.Value)[0]["providerName"]);
                }
              }
            }
          });
        });
      } else {
        reject();
      }
    });
  };

  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          //console.log("onSuccess: ", data);
          setAuthenticated(true);
          resolve(data);
        },
        onFailure: (err) => {
          //console.error("onFailure :", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          //console.log("newPasswordRequired: ", data);
          resolve(data);
        },
      });
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    setAuthenticated(false);
  };

  const accessToken = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      console.log("getSession user data: ", user);
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          }
          resolve(session.getIdToken().getJwtToken());
        });
      } else {
        reject();
      }
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        getUserName,
        accessToken,
        username,
        firstName,
        lastName,
        email,
        setAuthenticated,
        authenticated,
        environment,
        setEnvironment,
        internal,
        facades,
        updateFacades,
        provider
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
