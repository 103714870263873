import React, { useState, useContext } from "react";
import ReadyOne from "../images/readyone.png";
import { VscAccount } from "react-icons/vsc";
import styles from "./Navbar.module.css";
import { Account, Quicklinks } from "./NavItems";
import { Link } from "react-router-dom";
import { AccountContext } from "../../auth";
import ImageHelper from "../../components/ImageHelper";

export default function Navbar(props) {
  const [path, setPath] = useState("/Applications");
  const [quicklinksOpen, setQuicklinksOpen] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const { firstName } = useContext(AccountContext);

  const tabs = [
    {
      route: "/Applications",
      label: "Applications",
    },
    // {
    //   route: "/How-To-Resources",
    //   label: "How-To Resources",
    // },
    // {
    //   route: "/Request-Form",
    //   label: "Request Form",
    // },
  ];

  const getActiveClass = (name) => (name === path ? styles.active : "");

  return (
    <nav className={styles.navbar}>
      <div className={styles.brandLogo}>
        {" "}
        <ImageHelper
          mode="viprheader"
          className={styles.viprHeader}
          onClick={() => {}}
        />
      </div>
      <ul className={styles.navLinks}>
        {tabs.map((tab) => (
          <li className={`${styles.navLink} ${getActiveClass(tab.route)}`}>
            <Link to={tab.route} onClick={() => setPath(tab.route)}>
              <a>{tab.label}</a>
            </Link>
          </li>
        ))}
        {/* <li
          className={`${styles.navLink} ${getActiveClass("/Quicklinks")}`}
          onClick={() => setQuicklinksOpen(!quicklinksOpen)}
        >
          Quick Links
          <Quicklinks
            setPath={setPath}
            path={path}
            show={quicklinksOpen}
            close={() => {
              setTimeout(() => setQuicklinksOpen(false), 200);
            }}
          ></Quicklinks>
        </li> */}
      </ul>
      <ul className={styles.account}>
        <li>
          <span>{`Welcome, ${firstName}!`}</span>
        </li>
        <li>
          <VscAccount onClick={() => setAccountOpen(!accountOpen)} />
          <Account
            show={accountOpen}
            close={() => {
              setTimeout(() => setAccountOpen(false), 200);
            }}
            email={props.email}
            firstName={props.firstName}
            lastName={props.lastName}
            setAuthenticated={props.setAuthenticated}
            permissions={props.permissions}
          ></Account>
        </li>
      </ul>
    </nav>
  );
}
