import React from "react";
import styles from "./VIPRAccountRequestForm.module.css";

export var formDataInit = {
  id: "",
  cacCard: false,
  cacSubject: "",
  SAICEmployee: "",
  accountType: "",
  firstName: "",
  lastName: "",
  middleName: "",
  legalSuffix: "",
  email: "",
  businessPhone: "",
  mobilePhone: "",
  jobTitle: "",
  partnerType: "",
  date: "",
  legalCompanyName: "",
  companyPOC: "",
  companyPOCPhone: "",
  companyPOCEmail: "",
  companyWorkAddress: "",
  securityQuestion1: "",
  securityQuestion2: "",
  securityQuestion3: "",
  saicSponsorFirstName: "",
  saicSponsorMiddleName: "",
  saicSponsorLastName: "",
  saicSponsorEmail: "",
  role: "",
  program: "",
  physicalLocation: "",
  workDesignation: "",
  locationOfWork: "",
  ssn: ""
};

export const steps = [
  {
    // title: "CAC Registration",
    requiredFields: [],
  },
  {
    // title: "SAIC Employee",
    requiredFields: ["FormSelect"],
  },
  {
    // title: "Account Type",
    requiredFields: ["accountType"],
  },
  {
    title: "Contact Information",
    requiredFields: [
      "firstName",
      "lastName",
      "email",
      "businessPhone",
      "partnerType",
      "role",
      "program"
    ],
  },
  {
    title: "Company Information",
    requiredFields: [
      "legalCompanyName",
      "companyPOC",
      "companyPOCPhone",
      "companyPOCEmail",
    ],
  },
  {
    title: "Security Questions/Answers",
    requiredFields: ["securityQuestion1", "securityQuestion2"],
  },
  {
    title: "SAIC Sponsor Information",
    requiredFields: [
      "saicSponsorFirstName",
      "saicSponsorLastName",
      "saicSponsorEmail",
    ],
  },
];

export const corpNonEmployeeSteps = [
  {
    // title: "CAC Registration",
    requiredFields: [],
  },
  {
    // title: "SAIC Employee",
    requiredFields: ["FormSelect"],
  },
  {
    // title: "Account Type",
    requiredFields: ["accountType"],
  },
  {
    title: "Contact Information",
    requiredFields: [
      "firstName",
      "lastName",
      "email",
      "businessPhone",
      "partnerType",
      "role",
      "program"
    ],
  },
  {
    title: "Company Information",
    requiredFields: [
      "legalCompanyName",
      "companyPOC",
      "companyPOCPhone",
      "companyPOCEmail",
    ],
  },
  {
    title: "Security Information",
    requiredFields: ["workDesignation", "locationOfWork", "physicalLocation", "ssn"],
  },
  {
    title: "SAIC Sponsor Information",
    requiredFields: [
      "saicSponsorFirstName",
      "saicSponsorLastName",
      "saicSponsorEmail",
    ],
  },
];

export const employeeSteps = [
  {
    title: "CAC Registration",
    requiredFields: [],
  },
  {
    title: "SAIC Employee",
    requiredFields: ["FormSelect"],
  },
  {
    title: "SAIC Information",
    requiredFields: ["email", "saicSponsorEmail", "role", "program"],
  },
];

export const Submitted = ({ closeRegForm }) => {
  return (
    <div className={styles.confirmationPage}>
      <div className={styles.step5Content}>
        <svg
          className={styles.checkmark}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className={styles.checkmarkCircle}
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            className={styles.checkmarkCheck}
            fill="none"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
      </div>
      <div className={styles.submitCont}>
        <div className={styles.submitText}>
          Thank you, we will contact you shortly.
        </div>
        <button className={styles.nextBtn} onClick={closeRegForm}>
          Close
        </button>
      </div>
    </div>
  );
};

export const AccountType = ({ formData, handleChange, nextStep }) => {
  return (
    <div className={styles.saicEmployeeContent}>
      <span className={styles.submitText}>{`What type of account are you requesting?`}</span>
      <div className={styles.btnContainer}>
        <button
          className={styles.accountType}
          onClick={() => {
            handleChange({ target: { value: "corporate", name: "accountType" } })
          }
          }
        >
          Corporate Account
        </button>
        <button
          className={styles.accountType}
          onClick={() => {
            handleChange({ target: { value: "pne", name: "accountType" } })
          }
          }
        >
          Partner Non-Employee
        </button>
      </div>
    </div>
  );
};

export const FormSelection = ({ formData, handleChange }) => {
  return (
    <div className={styles.saicEmployeeContent}>
      <span className={styles.submitText}>{`Are you a SAIC employee?`}</span>
      <div className={styles.btnContainer}>
        <button
          className={styles.yesBtn}
          onClick={() =>
            handleChange({ target: { value: "Yes", name: "SAICEmployee" } })
          }
        >
          Yes
        </button>
        <button
          className={styles.noBtn}
          onClick={() =>
            handleChange({ target: { value: "No", name: "SAICEmployee" } })
          }
        >
          No
        </button>
      </div>
    </div>
  );
};

export const CACSelection = ({ formData, handleChange, nextStep }) => {
  const handleButtonClick = (value) => {
    const event = {
      target: {
        name: "cacCard",
        value: value, // true for Yes or false for No
      },
    };
    handleChange(event);
  };

  return (
    <div className={styles.saicEmployeeContent}>
      <label className={styles.submitText}>
        {formData.cacSubject
          ? "CAC Info: "
          : "Would you like to register a CAC card with your account?"}
      </label>
      {formData.cacSubject && <span className={styles.cacInfo}>{formData.cacSubject}</span>}
      <div className={styles.btnContainer}>
        {!formData.cacSubject && (
          <>
            <button
              className={styles.yesBtn}
              onClick={() => handleButtonClick(true)}
            >
              Yes
            </button>
            <button className={styles.noBtn} onClick={() => nextStep()}>
              No
            </button>
          </>
        )}
      </div>
      <span className={styles.noteText}>Note: If you have an exisitng account, you can register your CAC card by selecting this <a href="/">link</a> which will take you back to the main page.  Select SSO login to authenticate to your program façade.  Once there, select the Account dropdown list located in the upper right (person icon), then select 'Register CAC'.</span>
    </div>
  );
};

export const ContactInformation = ({ formData, handleChange }) => {
  return (
    <div className={styles.step1Content}>
      <input
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        placeholder="First Name*"
        required
      />
      <input
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        placeholder="Last Name*"
        required
      />
      <input
        name="middleName"
        value={formData.middleName}
        onChange={handleChange}
        placeholder="Middle Name (if any)"
      />
      <input
        name="legalSuffix"
        value={formData.legalSuffix}
        onChange={handleChange}
        placeholder="Legal Suffix (if any)"
      />
      <input
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email Address*"
        required
      />
      <input
        name="businessPhone"
        value={formData.businessPhone}
        onChange={handleChange}
        placeholder="Business Phone*"
        required
      />
      <input
        name="mobilePhone"
        value={formData.mobilePhone}
        onChange={handleChange}
        placeholder="Mobile Phone"
      />
      <input
        name="jobTitle"
        value={formData.jobTitle}
        onChange={handleChange}
        placeholder="Job Title"
      />
      <select
        name="partnerType"
        value={formData.partnerType}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select Partner Type*
        </option>
        <option value="Customers">Customers</option>
        <option value="Supplier">Supplier</option>
        <option value="Agent of Customer">Agent of Customer</option>
        <option value="Consultants">Consultants</option>
        <option value="Temporary Employees">Temporary Employees</option>
        <option value="Contractors">
          Contractors (includes subcontractors)
        </option>
        {/* Add options based on your needs */}
      </select>
      <input
        name="program"
        value={formData.program}
        onChange={handleChange}
        placeholder="Program*"
        required
      />
      <select
        name="role"
        value={formData.role}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select Role
        </option>
        <option value="PMO">PMO</option>
        <option value="Chief Engineer">Chief Engineer</option>
        <option value="System Engineer">System Engineer</option>
        <option value="Software Developer">Software Developer</option>
        <option value="Mod/Sim">Mod/Sim</option>
        <option value="Integration & Test">Integration & Test</option>
        <option value="Cyber">Cyber</option>
        <option value="CM/DM">CM/DM</option>
        <option value="Logistics/Sustainment">Logistics/Sustainment</option>
        <option value="Finance">Finance</option>
        {/* Add options based on your needs */}
      </select>
    </div>
  );
};

export const CompanyInformation = ({ formData, handleChange }) => {
  return (
    <div className={styles.step2Content}>
      <input
        name="legalCompanyName"
        value={formData.legalCompanyName}
        onChange={handleChange}
        placeholder="Legal Company Name*"
        required
      />
      <input
        name="companyPOC"
        value={formData.companyPOC}
        onChange={handleChange}
        placeholder="Company Point of Contact*"
        required
      />
      <input
        name="companyPOCPhone"
        value={formData.companyPOCPhone}
        onChange={handleChange}
        placeholder="Company POC Phone*"
        required
      />
      <input
        name="companyPOCEmail"
        value={formData.companyPOCEmail}
        onChange={handleChange}
        placeholder="Company POC Email*"
        required
      />
      <textarea
        name="companyWorkAddress"
        value={formData.companyWorkAddress}
        onChange={handleChange}
        placeholder="Company Work Address"
        rows="4"
      ></textarea>
    </div>
  );
};

export const SecurityInformation = ({ formData, handleChange }) => {
  return (
    <div className={styles.step3Content}>
      <input
        name="workDesignation"
        value={formData.workDesignation}
        onChange={handleChange}
        placeholder="Work Designation*"
        required
      />
      <input
        name="locationOfWork"
        value={formData.locationOfWork}
        onChange={handleChange}
        placeholder="Location of Work*"
        required
      />
      <input
        name="physicalLocation"
        value={formData.physicalLocation}
        onChange={handleChange}
        placeholder="Physical Location*"
        required
      />
      <input
        name="ssn"
        value={formData.ssn}
        onChange={handleChange}
        placeholder="Last 4 Digits of SSN*"
        required
      />
    </div>
  );
};

export const SecurityQuestions = ({ formData, handleChange }) => {
  return (
    <div className={styles.step3Content}>
      <input
        name="securityQuestion1"
        value={formData.securityQuestion1}
        onChange={handleChange}
        placeholder="Provide a 4-digit number that only you should know*"
        required
      />
      <input
        name="securityQuestion2"
        value={formData.securityQuestion2}
        onChange={handleChange}
        placeholder="What is your favorite animal?*"
        required
      />
      <input
        name="securityQuestion3"
        value={formData.securityQuestion3}
        onChange={handleChange}
        placeholder="What is your favorite color?*"
        required
      />
    </div>
  );
};

export const SponsorInformation = ({ formData, handleChange }) => {
  return (
    <div className={styles.step4Content}>
      <input
        name="saicSponsorFirstName"
        value={formData.saicSponsorFirstName}
        onChange={handleChange}
        placeholder="SAIC Sponsor First Name*"
        required
      />
      <input
        name="saicSponsorMiddleName"
        value={formData.saicSponsorMiddleName}
        onChange={handleChange}
        placeholder="SAIC Sponsor Middle Name"
      />
      <input
        name="saicSponsorLastName"
        value={formData.saicSponsorLastName}
        onChange={handleChange}
        placeholder="SAIC Sponsor Last Name*"
        required
      />
      <input
        name="saicSponsorEmail"
        value={formData.saicSponsorEmail}
        onChange={handleChange}
        placeholder="SAIC Sponsor Email*"
        required
      />
    </div>
  );
};

export const ButtonContainer = ({
  currentStep,
  steps,
  prevStep,
  nextStep,
  handleSubmit,
  disableNext
}) => {
  return (
    <div className={styles.btnContainer}>
      <button
        className={styles.nextBtn}
        disabled={currentStep === 0}
        onClick={prevStep}
      >
        Previous
      </button>
      {currentStep === steps.length - 1 ? (
        <button className={styles.nextBtn} disabled={disableNext} onClick={nextStep}>
          Submit
        </button>
      ) : (
        <button className={styles.nextBtn} disabled={disableNext} onClick={nextStep}>
          Next
        </button>
      )}
    </div>
  );
};

export const FormContainer = ({
  submitted,
  closeRegForm,
  currentStep,
  steps,
  prevStep,
  nextStep,
  handleSubmit,
  children,
  disableNext
}) => {
  return (
    <div className={styles.form}>
      <div className={styles.modalContent}>
        {submitted ? (
          <Submitted closeRegForm={closeRegForm} />
        ) : (
          <>
            <h2>Registration Form</h2>
            <div className={styles.progressBar}>
              <div
                className={styles.progress}
                style={{
                  width: `${((currentStep + 1) / steps.length) * 100}%`,
                }}
              ></div>
            </div>
            <div className={styles.step}>
              <h2>{steps[currentStep].title}</h2>
              <p>{steps[currentStep].content}</p>
              {children}
            </div>
            <ButtonContainer
              currentStep={currentStep}
              steps={steps}
              prevStep={prevStep}
              nextStep={nextStep}
              handleSubmit={handleSubmit}
              disableNext={disableNext}
            />
          </>
        )}
      </div>
    </div>
  );
};

export const SAICInformation = ({ formData, handleChange }) => {
  return (
    <div className={styles.step1Content}>
      <input
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email*"
        required
      />
      <input
        name="saicSponsorEmail"
        value={formData.saicSponsorEmail}
        onChange={handleChange}
        placeholder="Sponsor Email*"
        required
      />
      <input
        name="program"
        value={formData.program}
        onChange={handleChange}
        placeholder="Program*"
        required
      />
      <select
        name="role"
        value={formData.role}
        onChange={handleChange}
        required
      >
        <option value="" disabled>
          Select Role
        </option>
        <option value="PMO">PMO</option>
        <option value="Chief Engineer">Chief Engineer</option>
        <option value="System Engineer">System Engineer</option>
        <option value="Software Developer">Software Developer</option>
        <option value="Mod/Sim">Mod/Sim</option>
        <option value="Integration & Test">Integration & Test</option>
        <option value="Cyber">Cyber</option>
        <option value="CM/DM">CM/DM</option>
        <option value="Logistics/Sustainment">Logistics/Sustainment</option>
        <option value="Finance">Finance</option>
        {/* Add options based on your needs */}
      </select>
    </div>
  );
};
