export function getAppStreamURL(accessToken, application, environment, provider) {
  var myHeaders = new Headers();
  console.log(accessToken);
  myHeaders.append("Authorization", accessToken);
  //myHeaders.append("Content-Type", "application/json");
  let body = {
    application: application,
    environment: environment
  };
  let url = "https://ziz52cjaal.execute-api.us-gov-west-1.amazonaws.com/auth/vipr-auth"

  if(provider === "SAIC") {
    url = "https://ziz52cjaal.execute-api.us-gov-west-1.amazonaws.com/auth/vipr-auth-saic"
  }

  let raw = JSON.stringify(body);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  return fetch(
    url,
    requestOptions
  ).then((response) => response.json());
}
