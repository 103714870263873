import copyImg from "../images/copy.png";
import saicImg from "../images/saic.png";
import saiccopyImg from "../images/saiccopy.png";
import saiclogoImg from "../images/saiclogo.png";
import newROlogoImg from "../images/newROlogo.png";
import ViprLogoImg from "../images/VIPRLogo.png";
import bg2Img from "../images/bg2.png";
import bgviprlogoImg from "../images/bgviprlogo.png";
import viprbgblankImg from "../images/viprbgblank.png";
import viprheaderImg from "../images/viprheader.png";
import readyoneImg from "../images/readyone.png";
import React from "react";

const iconObj = {
  copy: copyImg,
  saic: saicImg,
  mock: saiccopyImg,
  saiclogo: saiclogoImg,
  readyone: readyoneImg,
  newRO: newROlogoImg,
  ViprLogo: ViprLogoImg,
  ViprBG: bg2Img,
  viprbgblank: viprbgblankImg,
  bgviprlogo: bgviprlogoImg,
  viprheader: viprheaderImg,
};

class ImageHelper extends React.Component {
  render() {
    return (
      <img
        style={{
          width: this.props.width,
          color: this.props.color,
          justifyContent: "center",
        }}
        alt={this.props.mode}
        src={iconObj[this.props.mode] || iconObj["defaultapp"]}
        className={this.props.className}
        onClick={() => this.props.onClick()}
      />
    );
  }
}

ImageHelper.defaultProps = {
  width: "1.5rem",
  color: "gainsboro",
};

export default ImageHelper;
