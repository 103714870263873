import React, { useState, useEffect, useContext } from "react";
// We are using axios as the http library
import { BsArrowLeftShort } from "react-icons/bs";
import styles from "./FileUpload.module.css";
import { AccountContext } from "../../../../auth";
import Modal from "../Modal/Modal";
import { uploadFile, listFiles } from "../../../../services/ReadyOneAPI";

const FileUpload = ({ show, close }) => {
  // fileToUpload contains the actual file object
  // uploadSuccess becomes true when the file upload is complete
  const [files, setFiles] = useState([]);
  const [fileToUpload, setFileToUpload] = useState(undefined);
  const [uploadSuccess, setUploadSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [folder, setFolder] = useState("");
  const [visFiles, setVisFiles] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const { username } = useContext(AccountContext);

  useEffect(() => {
    listFiles(username).then((result) => {
      setFiles(result[0].files);
      setVisFiles(result[0].files);
      setPrevFiles((prevFiles) => [...prevFiles, result[0]]);
    });
  }, []);

  const upload = () => {
    uploadFile(fileToUpload, fileToUpload.name, username).then((result) => {
      console.log(result);

      setUploadSuccess(result.uploadSuccess);
      setError(result.error);
    });
  };

  const handleBack = () => {
    if (prevFiles.length > 1) {
      const lastFile = prevFiles[prevFiles.length - 2];
      setPrevFiles((prevFiles) => prevFiles.slice(0, -1));

      console.log(lastFile);
      setVisFiles(lastFile.files);
      setFolder(lastFile.title);
    }
  };

  return (
    <Modal
      id="notification-modal"
      show={show}
      title={"Upload File"}
      buttonText={"Upload"}
      onClick={() => {
        upload();
      }}
      hideModal={close}
    >
      <div className={styles.editContainer}>
        
          <div className={styles.selectContainer}>
            <form className={styles.fileSelectCont}>
             
                {/* <label for="fileUpload" className={styles.fileButton} /> */}
                <input
                  type="file"
                  className={styles.fileButtonText}
                  id="fileUpload"
                  onChange={(e) => {
                    setFileToUpload(e.target.files[0]);
                  }}
                />

                {fileToUpload ? (
                  <button
                    type="button"
                    className={styles.uploadButton}
                    onClick={(e) => {
                      upload();
                    }}
                  >
                    Upload your file
                  </button>
                ) : null}
                <div>
                  <span>{uploadSuccess ? "File Upload Successfully" : ""}</span>
                </div>
              
            </form>
          </div>
        </div>
        <div className={styles.folderSection}>
          <div className={styles.folderHeader}>
            {/* <button className={styles.backButton} onClick={() => handleBack()}> */}
              <BsArrowLeftShort onClick={() => handleBack()} className={styles.backButton}/>
              
            {/* </button> */}
            <h2 className={styles.folderTitle}>{folder}</h2>
          </div>
          <ul className={styles.files}>
            {visFiles.map((file) => (
              <li
                className={`${file.isDirectory ? styles.isDirectory : ""} ${styles.file
                  }`}
                onClick={() => {
                  if (!file.isDirectory) {
                    window.open(file.downloadLink);
                  } else {
                    setPrevFiles((prevFiles) => [...prevFiles, file]);
                    setFolder(file.title);
                    setVisFiles(file.files);
                  }
                }}
              >
                {file.title}
              </li>
            ))}
          </ul>
        </div>
    </Modal>
  );
};

export default FileUpload;
