import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { ToastContext } from "../../../Toasts";
import { AccountContext } from "../../../../auth";
import env from "./EnvironmentEdit.module.css";
import { BsXLg } from "react-icons/bs";
import {
  getTable,
  getUserList,
  updateFacade,
  getFacades,
} from "../../../../services/ReadyOneAPI";

const EnvironmentEdit = ({ show, close }) => {
  const [facades, setFacades] = useState([]);
  const [facade, setFacade] = useState(null);
  const [apps, setApps] = useState([]);
  const [users, setUsers] = useState([]);
  // New users or add apps
  const [newUser, setNewUser] = useState("");
  const [newApp, setNewApp] = useState("");
  // Selected users or environments
  const [selectedEnvironments, setSelectedEnvironments] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedApplications, setSelectedApplications] = useState([]);

  const { notifySuccess } = useContext(ToastContext);
  const { updateFacades } = useContext(AccountContext);

  useEffect(() => {
    getFacades().then((result) => {
      setFacades(result);
      setFacade(result[0]);
      getTable("VIPR_AppStreams").then((result) => {
        setApps(result.body.Items);
        getUserList().then((result) => {
          setUsers(result);
        });
      });
    });
  }, []);

  useEffect(() => {
    console.log(facade);
  }, [facade]);

  const handleUpdate = () => {
    updateFacade(facade).then((result) => {
      notifySuccess(`Updated ${result.title}`, 500);
      updateFacades();
    });
  };

  return (
    <div
      show={show}
      className={`${env.container} ${show ? env.show : env.hide}`}
    >
      <div className={env.editContainer}>
        <div>
          <h1 className={env.header}>
            Select Environment, Users, and Applications
            <BsXLg className={env.closeBtn} onClick={close} title={"Close"} />
          </h1>
        </div>
        <div className={env.selectContainer}>
          <label className={env.selectHeader}>Environment:</label>
          <Select
            className={env.boxSelector}
            isSearchable
            value={{ label: facade?.title, value: facade?.id }}
            options={facades.map((facade) => {
              return { label: facade.title, value: facade.id };
            })}
            onChange={(selectedOption) => {
              const selectedFacade = facades.find(
                (facade) => facade.id === selectedOption.value
              );
              setFacade(selectedFacade);
            }}
          />
        </div>
        {facade && (
          <>
            <div className={env.selectContainer}>
              <label className={env.selectHeader}>Users:</label>
              <CreatableSelect
                className={env.boxSelector}
                isMulti
                isClearable
                isSearchable
                value={facade.users.map((user) => {
                  return { label: user, value: user };
                })}
                options={users.map((user) => {
                  return { label: user, value: user };
                })}
                onChange={(selectedUsers) => {
                  // selectedUsers is an array of selected users
                  // Simply replace the previous users array with the selectedUsers array
                  setFacade((prevState) => {
                    return {
                      ...prevState,
                      users: selectedUsers.map((user) =>
                        user.value.toLowerCase()
                      ),
                    };
                  });
                }}
              />
            </div>

            <div className={env.selectContainer}>
              <label className={env.selectHeader}>Applications:</label>
              <Select
                className={env.boxSelector}
                isMulti
                isClearable
                isSearchable
                value={facade.apps.map((app) => {
                  return { label: app.title, value: app.appName };
                })}
                options={apps.map((app) => {
                  return { label: app.title, value: app.appName };
                })}
                onChange={(selectedApps) => {
                  const selectedOptions = apps.filter((app) =>
                    selectedApps.some(
                      (selectedApp) => selectedApp.value === app.appName
                    )
                  );
                  console.log(selectedOptions);
                  setFacade((prevState) => {
                    return { ...prevState, apps: selectedOptions };
                  });
                }}
              />
            </div>
          </>
        )}
        <div className={env.btnContainer}>
          <button className={env.cancelBtn} onClick={close}>
            Cancel
          </button>

          <button className={env.submitBtn} onClick={() => handleUpdate()}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentEdit;
