const apiURL = "https://api.readyone.net";
const apiKey = "byGTHF1wmJ1nxf7eMp7fNytA8M9PWwLsIU85or90";

export function getHowTo_old() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/gethowto`, requestOptions)
    .then((response) => response.json())
    .then((body) => body.body);
}

export function getTable(tableName) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ tableName: tableName });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/getTable`, requestOptions).then((response) =>
    response.json()
  );
}

export function sendEmail(Data) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(Data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/sendEmail`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function getHowTo() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  // myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/gets3structure`, requestOptions)
    .then((response) => response.json())
    .then((body) => body.body);
}

export function search(searchTerm) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/search?q=${searchTerm}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result.hits.hits)
    .catch((error) => console.log("error", error));
}

export function searchWorkItems(searchTerm) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/search/work-items?q=${searchTerm}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result.hits.hits)
    .catch((error) => console.log("error", error));
}

export function submitFeedback(feedback) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(feedback);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/submit-feedback`, requestOptions).then((response) =>
    response.json()
  );
}

export function getUserApps(id) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({ id, environment: "VIPR_UserApps" });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get-user-apps`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function updateUserApps(id, userApps) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    id,
    appList: userApps,
    environment: "VIPR_UserApps",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/update-user-apps`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

// export function getNotifications() {
//   var myHeaders = new Headers();
//   myHeaders.append("X-Api-Key", apiKey);
//   myHeaders.append("Content-Type", "application/json");

//   var raw = JSON.stringify({ environment: "VIPR_Notifications" });

//   var requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };

//   return fetch(`${apiURL}/get-notifications`, requestOptions)
//     .then((response) => response.json())
//     .catch((error) => console.log("error", error));
// }

// export function updateNotifications(notification) {
//   var myHeaders = new Headers();
//   myHeaders.append("X-Api-Key", apiKey);
//   myHeaders.append("Content-Type", "application/json");

//   var raw = JSON.stringify({
//     environment: "VIPR_Notifications",
//     notification,
//   });

//   var requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: raw,
//     redirect: "follow",
//   };

//   return fetch(`${apiURL}/update-notifications`, requestOptions)
//     .then((response) => response.json())
//     .catch((error) => console.log("error", error));
// }

export function checkIP() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/check-ip`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}


export function getNotifications() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get-vipr-notifications`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function postNotification(notification) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(notification);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get-vipr-notifications`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

export function deleteNotification(id) {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    id,
  });

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/get-vipr-notifications`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}


export function getFacades() {
  var myHeaders = new Headers();
  myHeaders.append("X-Api-Key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/vipr-facades`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function addFacade(facade) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(facade);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/vipr-facades`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function updateFacade(facade) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(facade);

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(`${apiURL}/vipr-facades`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function deleteFacade(facade) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${apiURL}/vipr-facades?id=${facade}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
}

export function getUserFacades(username) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    username: username
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(`${apiURL}/vipr-facades/get-items-by-user`, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));
}

export function getUserList() {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${apiURL}/vipr-facades/get-users`, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));
}

export function uploadFile(file, name, username) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", apiKey);
    myHeaders.append("Content-Type", "application/json");


    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    // When the upload file button is clicked,
    // first we need to get the presigned URL
    // URL is the one you get from AWS API Gateway
    return fetch(
      `${apiURL}/upload-vipr-s3/generate-upload-url?fileName=${username}/${name}`, requestOptions
    ).then(async (response) => {
      const data = await response.json();
      console.log(data)
      // Getting the url from response
      const url = data.fileUploadURL;

      // Initiating the PUT request to upload file    
      return fetch(url, {
        method: "PUT",
        body: file,
        headers: { "Content-Type": "multipart/form-data" }
      }).then(uploadResponse => {
        if (uploadResponse.ok) {
          return ({
            uploadSuccess: "File upload successful",
            error: undefined
          });
        } else {
          throw new Error("Upload failed");
        }
      })


    })


  } catch (err) {
    return ({
      error: "Error Occurred while uploading the file",
      uploadSuccess: undefined
    });
  }
}

export function listFiles(username) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");


  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  return fetch(`${apiURL}/upload-vipr-s3/file-list?fileName=${username}`, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));
}

export function getCACSubject() {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };

  return fetch("https://cac.readyone.net/cac-registration", requestOptions).then(response => response.json()).catch(error => console.log('error', error));
}

export function registerCAC(formData) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(formData);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(`${apiURL}/vipr-registration`, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));
}

export function submitRegistration(formData) {
  var myHeaders = new Headers();
  myHeaders.append("x-api-key", apiKey);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(formData);

  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  return fetch(`${apiURL}/vipr-registration`, requestOptions)
    .then(response => response.json())
    .catch(error => console.log('error', error));
}